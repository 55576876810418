<template>
  <div class="chats">
    <div
      class="chat"
      v-for="message in messages"
      :key="message.index"
      :class="{ 'chat-left': message.state.author != 'test@beste.it' }"
    >
      <div class="chat-avatar">
        <b-avatar
          v-if="message.state.first == true"
          size="30"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="require('@/assets/images/logo/beste_logo.png')"
        />
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <p v-if="message.type !== 'media'">{{ message.state.body }}</p>
          <b-img
            v-if="message.type == 'media' && message.state.mediaUrl"
            :src="message.state.mediaUrl"
            width="150"
            class="mb-1"
            rounded
          />
          <small>{{ parseTime(message.state.timestamp) }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BImg } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BAvatar,
    BImg,
  },
  props: {
    activeConversation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
    }
  },
  mounted() {
    this.activeConversation.getMessages().then((newMessages) => {
      this.messages = [...this.messages, ...newMessages.items]
      this.parseMessages()
    })
    this.activeConversation.on('messageAdded', (message) => {
      this.messages = [...this.messages, message]
      this.parseMessages()
    })
  },
  methods: {
    async parseMessages() {
      const parsedMessages = []
      for (let index = 0; index < this.messages.length; index++) {
        const message = this.messages[index]
        if (message.type === 'media') {
          message.state.mediaUrl = await message.media.getContentTemporaryUrl()
        }
        if (index == 0) {
          message.state.first = true
          parsedMessages.push(message)
          continue
        }
        if (message.state.author == this.messages[index - 1].state.author) {
          message.state.first = false
          parsedMessages.push(message)
        }
        if (message.state.author != this.messages[index - 1].state.author) {
          message.state.first = true
          parsedMessages.push(message)
        }
      }
      this.messages = parsedMessages
    },
    parseTime(date) {
      return moment(date).format('HH:mm')
    },
  },
}
</script>

<style></style>
